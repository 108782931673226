// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-content-parent {
    position: relative;
}

.store-content-row {
    display: flex;
    margin: 0 -1rem;
    margin-bottom: 2rem;
    align-items: center;
}

.store-item-parent {
    width: 25%;
    background-color: #ffffff;
    margin: 0 1rem 0;
    padding: 2rem;
    position: relative;
    border-radius: .4rem;
    border: .5rem solid transparent;
}

.store-item-parent.active {
    box-sizing: border-box;
    border: .5rem solid #47b7e4;
}

.store-item-image {
    width: 100%;
    height: 35rem;
    object-fit: cover;
}

.store-item-title {
    min-height: 4.8rem;
    font-size: 2rem;
    font-weight: bold;
    margin-top: 1rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
}

.store-item-price {
    font-size: 2.6rem;
    font-weight: bold;
    padding: 1rem 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    border-bottom: 2px solid #000;
}

.scan-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.scan-link {
    color: #47b7e4;
    font-size: 2rem;
}

.scan-text {
    font-size: 2rem;
}`, "",{"version":3,"sources":["webpack://./styles/store.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,yBAAyB;IACzB,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,oBAAoB;IACpB,+BAA+B;AACnC;;AAEA;IACI,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,oBAAoB;IACpB,4BAA4B;IAC5B,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;IACf,oBAAoB;IACpB,4BAA4B;IAC5B,gBAAgB;IAChB,qBAAqB;IACrB,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".store-content-parent {\n    position: relative;\n}\n\n.store-content-row {\n    display: flex;\n    margin: 0 -1rem;\n    margin-bottom: 2rem;\n    align-items: center;\n}\n\n.store-item-parent {\n    width: 25%;\n    background-color: #ffffff;\n    margin: 0 1rem 0;\n    padding: 2rem;\n    position: relative;\n    border-radius: .4rem;\n    border: .5rem solid transparent;\n}\n\n.store-item-parent.active {\n    box-sizing: border-box;\n    border: .5rem solid #47b7e4;\n}\n\n.store-item-image {\n    width: 100%;\n    height: 35rem;\n    object-fit: cover;\n}\n\n.store-item-title {\n    min-height: 4.8rem;\n    font-size: 2rem;\n    font-weight: bold;\n    margin-top: 1rem;\n    display: -webkit-box;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    -webkit-line-clamp: 2;\n}\n\n.store-item-price {\n    font-size: 2.6rem;\n    font-weight: bold;\n    padding: 1rem 0;\n    display: -webkit-box;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    -webkit-line-clamp: 2;\n    border-bottom: 2px solid #000;\n}\n\n.scan-wrapper {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 1rem;\n}\n\n.scan-link {\n    color: #47b7e4;\n    font-size: 2rem;\n}\n\n.scan-text {\n    font-size: 2rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
