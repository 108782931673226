// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.under-maintenance {
    background: #000;
    text-align: center;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.under-maintenance-content {
    width: 50%;
    margin: 0 auto;
}

.under-maintenance-title, .under-maintenance-description {
    margin-bottom: 50px;
    color: #fff;
    font-size: 50px;
    font-weight: 500;
}

.under-maintenance-logo {
    height: 70px;
}`, "",{"version":3,"sources":["webpack://./styles/underMaintenance.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".under-maintenance {\n    background: #000;\n    text-align: center;\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.under-maintenance-content {\n    width: 50%;\n    margin: 0 auto;\n}\n\n.under-maintenance-title, .under-maintenance-description {\n    margin-bottom: 50px;\n    color: #fff;\n    font-size: 50px;\n    font-weight: 500;\n}\n\n.under-maintenance-logo {\n    height: 70px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
