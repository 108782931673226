import controles from "../remote/controles";
import appSettings from "../data/appSettings.json";

export default class UserCentrics {

    static delay = 2000;

    constructor(){
        this.#init();
    };

    #init(){
        if(!appSettings.production) return;

        const centricsPlugins = newPlugins.plugins[2];
        const brandMeasurement = centricsPlugins.settingsId;
        if(!brandMeasurement) return;

        const head = document.head;

        // Preconnect link
        const preconnectLink = document.createElement("link");
        preconnectLink.rel = "preconnect";
        preconnectLink.href = "//privacy-proxy.usercentrics.eu";
        head.appendChild(preconnectLink);

        // Preload link
        const preloadLink = document.createElement("link");
        preloadLink.rel = "preload";
        preloadLink.href = "//privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js";
        preloadLink.as = "script";
        head.appendChild(preloadLink);

        // Script for uc-block.bundle.js
        const ucBlockScript = document.createElement("script");
        ucBlockScript.type = "application/javascript";
        ucBlockScript.src = "https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js";
        head.appendChild(ucBlockScript);

        // Script for Usercentrics CMP loader
        const userCentricsScript = document.createElement("script");
        userCentricsScript.id = "usercentrics-cmp";
        userCentricsScript.src = "https://web.cmp.usercentrics.eu/ui/loader.js";
        // userCentricsScript.dataset.settingsId = 'btvO1AohMrztkT' // brandMeasurement;
        userCentricsScript.dataset.settingsId = 'DzGvSycxMx_szF' // brandMeasurement;
        // userCentricsScript.dataset.settingsId = 'i8WwQiMe2C2osZ' // brandMeasurement;
        // userCentricsScript.dataset.settingsId = brandMeasurement;
        userCentricsScript.async = true;

        userCentricsScript.onload = () => {
            setTimeout(() => {
                console.log("Usercentrics CMP loaded.");
                this.#onDialogLoaded();
            }, UserCentrics.delay);
        };

        head.appendChild(userCentricsScript);

    }

    #onDialogLoaded() {
        setTimeout(() => {
            const main = document.getElementById('usercentrics-cmp-ui')?.shadowRoot?.querySelector('#main-view');
            if(main){
                main.style.fontSize = '1.5em'

                controles.set_current("userCentrics");
                controles.userCentrics.set_current("home");
                controles.userCentrics.home.setItems();
            }
        }, UserCentrics.delay);
    }

    static showDialog() {
        window.__ucCmp.showFirstLayer()
        .then(_ => {
            setTimeout(() => {
                const main = document.getElementById('usercentrics-cmp-ui')?.shadowRoot?.querySelector('#main-view');
                if(main){
                    main.style.fontSize = '1.5em'

                    controles.set_current("userCentrics");
                    controles.userCentrics.set_current("home");
                    controles.userCentrics.home.setItems();
                }
            }, 500);
        });
    }
}

