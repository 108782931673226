import { underMaintenance } from "../components/underMaintenance";
import Beacons from "../services/beacons";
import { GET } from "./get";
import errorReport from "../services/errorReport";
import Parser from "./parser";
import ChannelSettings from "../settings/channelSettings";
import DeviceCenter from "../services/deviceCenter";


/**
 * translateJson
 *
 * @param {*} json
 * @param {*} vast
 * @returns {*}
 */
const translateJson = (json, vast) => {
  switch (json.Info.class) {
    case "7":
      return v7Init(json, vast);
    default:
      throw new Error(`Unsupported json version: ${json.Info.class}`);
  }
};

/**
 * load scripts by the platform
 *
 * @param {*} options
 * @returns {*}
 */
const loadScripts = (options) => {
  const listOffScriptsToLoad = [];

  if (options.pal) {
    listOffScriptsToLoad.push(() => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "//imasdk.googleapis.com/pal/sdkloader/pal.js";
        script.onload = () => resolve();
        script.onerror = () => reject();
        document.head.appendChild(script);
      });
    });
  }

  return Promise.all[listOffScriptsToLoad.map((promisefunc) => promisefunc())];
};

/**
 * function to initialize window settings 
 */
const setWindowData = () => {
  window.settings = {
    platform: "",
    platformSettings: {},
    appSettings: {},
    deepLinkData: {},
  };
};

/**
    *
    * @function generateLogicUrl
    * @description generates a logic url from the passed hash
    * @param {string} hash
    * @returns {string}
    *
 * Hash: TV unique id to determine a specific tv. will use to get the settings 
 * 4fpgggl6py => Fashion TV
 * njsfvtweaa => 24flix
 * s6xjws00o5 => Nollywood Capital TV
 * vklwkp7w2s => Unreal TV
 * ctxe85cvw5 => Auto Allstars
 * vwb1upv460 => Rep Dat TV
 * baqn4tlk8e => ScreenMagic TV
 * wdjzf9ayeb => DOC BOX
 * zvmhs9hfuj => black screen
 *  m1dk1729h5 => coffe jazz
 * j4lseacvi8 => bob the train
 * yzpgKjrDAM =>
 * xhxxamifyo => PopStar TV
 * 745mzqybbr => Made It Myself TV
 * j2r5jdz7xc => Brazil Times
 * 8mslt993x6 => High Octaine TV
 * 79gqu2zw9w => DangerTV
 *
 * @param {*} hash
 * @returns {string}
 */
const generateLogicUrl = (hash) => {
  return `https://logic.castify.ai/srv/logic/?hash=1rk064&cad[channel_id]=${hash}&rd=1&cad[client_type]=3&cad[channel_output]=_manifest`
}

/**
    * @function getLogicUrl
    * @description fetches the logic url of a channel hash, else defaults to high octane
    * @param {string} customHash
    * @returns {string}
    */
const getLogicUrl = (customHash) => {
  let hash;
  if (customHash) return generateLogicUrl(customHash);
  try {
    hash = window.location.search.split("=")[1]; // Browser
    if (hash) return generateLogicUrl(hash);
  } catch (error) {

  }
  const elem = document.getElementById("jsonUrl"); // TV devices
  if (elem) {
    let url = elem.innerText.trim();
    if (!url) throw new Error("No url found");
    return url;
  } else {
    return generateLogicUrl("8mslt993x6") // High Octaine TV as a default
  }
};

/**
    * @async
    * @function getLogic
    * @description fetches and extracts data from the logic url - final click url and device information
    * @param {string} customHash
    * @returns {unknown}
    */
const getLogic = async (customHash) => { 
  const { finalClickUrl, requestData = {} } = await GET(
    Parser.parse(getLogicUrl(customHash))
  );
  const { deviceData = {}, geoData = {} } = requestData;
  const userData = {
    ip: deviceData.ip,
    default_IFA: deviceData.deviceIfa,
    geoData,
    ua: {
      osName: deviceData.uaData.osName,
      osFamily: deviceData.uaData.osFamily,
      uaFamily: deviceData.uaData.uaFamily,
      deviceType: deviceData.uaData.deviceType,
    },
  };
  return {
    url: finalClickUrl,
    userData,
  };
};

/**
    * @async
    * @function destructures data from json composing the neccessary data into a menifest object
    * @param {string} url
    * @param {string} iso
    * @returns {Object}
    */
const getManifest = async (url, iso /*displaySplashScreen*/) => {
  const manifest = await GET(url);
  const channelStatus = manifest.channel_status;

  if (channelStatus) {
    if (channelStatus !== "Live")
      throw new Error(`App status: ${channelStatus}`, { cause: channelStatus });
  }

  return {
    translation_url: manifest.translation_url,
    splash_screen: manifest.splash_screen,
    feed: manifest.multi_geo_content[iso] || manifest.multi_geo_content.all,
    beacons: manifest.beacons,
    channel_settings_url: manifest.channel_settings_url,
  };
};


/**
 * use to get app data
 *
 * @async
 * @param {*} apiUrl
 * @param {*} dataToReplace
 * @returns {unknown}
 */
const getAppJson = async (apiUrl, dataToReplace) => {
  const _jsonTXT = await GET(apiUrl, { type: "text" });

  return JSON.parse(_jsonTXT.replace(/\$\{USER_COUNTRY\}/g, dataToReplace));
};

/**
 * Get translations
 *
 * @async
 * @param {*} url
 * @returns {unknown}
 */
const getTranslation = async (url) => {
  if (!url) return langData;

  try {
    return await GET(url);
  } catch (error) {
    return langData;
  }
};


/**
 * Get channel settings
 *
 * @async
 * @param {*} channelSettingsHash
 * @returns {unknown}
 */
const getSettings = async (channelSettingsHash) => {
  if (!channelSettingsHash) return ChannelSettings.init();

  try {
    const settings = await GET(channelSettingsHash);
    return ChannelSettings.init(settings);
  } catch (err) {
    return ChannelSettings.init();
  }
};

/**
 * Get app data with the hash id
 *
 * @async
 * @param {string} customHash
 * @returns {object}
 */
const getAppData = async (customHash) => {
    try {
        setWindowData();

        const logic = await getLogic(customHash);

        const ISO = logic.userData.geoData.cityGeoData.countryIsoCode;

        /**
            * @member {Object}
            * @description retries the manifest information of a channel. Manifest containes data i.e splash_screen, content_feed_url, channel_hash and beacons
            * */
            const manifest = await getManifest(logic.url, ISO);

        // display splash screen
        const splashScreen = manifest.splash_screen;
        if (splashScreen) {
            // displaySplashScreen(splashScreenUrl, splashScreenDuration);
        }

        const data = await Promise.allSettled([
            DeviceCenter.init(logic.userData, ISO),
            getAppJson(manifest.feed.content_feed_url, ISO),
            getTranslation(manifest.translation_url),
            getSettings(manifest.channel_settings_url),
        ]);

        loadScripts({
            pal: data[1].value.Ads.sdk_type === "pal",
        });

        const [beaconUrl, beaconSession, beaconVideo, vastURL] = Parser.init({
            data: logic.userData,
            urls: [
                manifest.beacons.url,
                manifest.beacons.url_session,
                manifest.beacons.url_video,
                data[1].value.Ads.vastURL,
            ],
        });

        console.log('vast url', vastURL)

        Beacons.init({ beaconUrl, beaconSession, beaconVideo });

        return {
            appJson: data[1].value,
            translation: data[2].value,
        };
    } catch (error) {

        // on error render maintenance screen
        errorReport.trackError({
            message: error.message,
            stack: error.stack,
        });

        const app_loader = document.getElementById("app_loader");
        if (app_loader) app_loader.classList.remove("show");
        console.log('error launching', error)
        underMaintenance();
        throw error;
    }
};

export { getLogic, getAppData };
