// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0_webpack-cli@4.10.0_/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-parent {
    width: 100%;
    height: 100%;
}

.popup-content {
    width: 100%;
    height: 100%;
}

.channel-info__close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 4rem;
    left: -9rem;
    width: 8rem;
    height: 8rem;
    color: #fff;
    font-size: 3rem;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
}

.channel-info__close-btn.active {
    background-color: #fff;
    color: #000;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
}`, "",{"version":3,"sources":["webpack://./styles/detailedPopup.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,WAAW;IACX,YAAY;IACZ,WAAW;IACX,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,oCAAoC;IACpC,UAAU;AACd","sourcesContent":[".popup-parent {\n    width: 100%;\n    height: 100%;\n}\n\n.popup-content {\n    width: 100%;\n    height: 100%;\n}\n\n.channel-info__close-btn {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: absolute;\n    top: 4rem;\n    left: -9rem;\n    width: 8rem;\n    height: 8rem;\n    color: #fff;\n    font-size: 3rem;\n    background-color: #000;\n    border-radius: 50%;\n    cursor: pointer;\n}\n\n.channel-info__close-btn.active {\n    background-color: #fff;\n    color: #000;\n}\n\n.popup-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 9;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
