/**
 * Ads management
 * Reference:  https://developers.google.com/ad-manager/pal/ctv
 */

import Player from "../pages/player";
import GlobalAnalytics from "../services/globalAnalytics";
import Analytics from "../services/analytics";
import parser from "../api/parser";
import HlsPlayer from "./common/hls";
import { el } from "../utils";
import appSettings from "../data/appSettings.json"

/**
 * adsLoaded: if ad is already loaded, make it as true, and use to disable re-attempts
 *
 * @type {boolean}
 */
var adsLoaded = false;
/**
 * retry_ad_break_index: If need to rety the ad due to ad failure, please set this as the current ad break index
 *
 * @type {number}
 */
var retry_ad_break_index = 0;
/**
 * isContentResumeRequested
 *
 * @type {boolean}
 */
var isContentResumeRequested = false;
/**
 * adContainer
 *
 * @type {*}
 */
var adContainer;
/**
 * adDisplayContainer
 *
 * @type {*}
 */
export var adDisplayContainer;
/**
 * adsLoader
 *
 * @type {*}
 */
var adsLoader;
/**
 * adsManager
 *
 * @type {*}
 */
export var adsManager;

/**
 * video player element
 *
 * @type {*}
 */
var videoElement;
/**
 * the interval between ads in seconds
 *
 * @type {*}
 */
var adInterval;
/**
 * channel information
 *
 * @type {*}
 */
var channelInfo;



/**
 * nonceLoader
 *
 * @type {*}
 */
let nonceLoader;
/**
 * managerPromise
 *
 * @type {*}
 */
let managerPromise;
/**
 * nonceManager
 *
 * @type {*}
 */
let nonceManager;
/**
 *storageConsent
 *
 * @type {boolean}
 */
let storageConsent = true;

/**
 * A placeholder for the publisher's own method of obtaining user
 * consent, either by integrating with a CMP or based on other
 * methods the publisher chooses to handle storage consent.
 * @return {boolean} Whether storage consent has been given.
 */
function getConsentToStorage() {
  return storageConsent;
}

/**
 * generate nonce
 */
function generateNonce() {
  try {

    // The default value for `allowStorage` is false, but can be
    // changed once the appropriate consent has been gathered.
    const consentSettings = new goog.ctv.pal.ConsentSettings();
    consentSettings.allowStorage = getConsentToStorage();

    nonceLoader = new goog.ctv.pal.NonceLoader(consentSettings);

    const request = new goog.ctv.pal.NonceRequest();
    request.adWillAutoPlay = false;
    request.adWillPlayMuted = false;
    request.continuousPlayback = false;
    request.iconsSupported = true;
    request.playerType = 'HlsPlayer';
    request.playerVersion = '1.0';
    request.ppid = generateRandomString(20);
    request.sessionId = channelInfo?.content_session_id;
    // Player support for VPAID 2.0, OMID 1.0, and SIMID 1.1
    request.supportedApiFrameworks = '2,7,9';
    request.url = 'https://developers.google.com/ad-manager/pal/ctv';
    request.videoHeight = videoElement?.clientHeight || 1080;
    request.videoWidth = videoElement?.clientWidth || 1920;

    managerPromise = nonceLoader.loadNonceManager(request);
    managerPromise
      .then((manager) => {
        nonceManager = manager;

      })
      .catch((error) => {
      });
  } catch (error) {
  }
}


/**
 * a variable to determine if there is a ads or not in the selected channel
 *
 * @type {boolean}
 */
export var hasAds = false;

// load ad with ad index.
export function load_ima_ads(index) {
  if (adsLoaded) {
    return;
  }
  adsLoaded = true;
  Player.current_ad_break_index = index;
  start_ads(index);
}

/**
 * request  ads
 *
 * @export
 */
export function requestAds() {
  if (adsLoaded) {
    return;
  }
  adsLoaded = true;
  var adsRequest = new google.ima.AdsRequest();
  var url = appData.Ads.vastURL;
  const macros = {
    "[VIDEO_TITLE]": channelInfo.title,
    "[VIDEO_CONTENT_ID]": channelInfo.id,
    "[PAL_STRING]": nonceManager?.nonce || ""
  };
  // replace macros
  const parsedUrl = parser.parse(url, macros);
  url = parsedUrl;
  adsRequest.adTagUrl = url;
  adsRequest.linearAdSlotWidth = videoElement.clientWidth;
  adsRequest.linearAdSlotHeight = videoElement.clientHeight;
  adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
  adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight / 3;

  const globalAnalytics = new GlobalAnalytics();
  const analytics = new Analytics(globalAnalytics);
  analytics.sendEvent("adRequest");
  analytics.sendEvent("adOpportunity");
  if (adDisplayContainer != null) adDisplayContainer.initialize();
  adsLoader.requestAds(adsRequest);
}

/**
 *stat ads with index
 *
 * @param {*} index
 */
function start_ads(index) {
  try {
    var adsRequest = new google.ima.AdsRequest();
    var url = Player.ad_breaks[index].url;
    Player.ad_breaks[index].played = true;
    adsRequest.adTagUrl = url;
    adsRequest.linearAdSlotWidth = videoElement.clientWidth;
    adsRequest.linearAdSlotHeight = videoElement.clientHeight;
    adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
    adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight / 3;

    const globalAnalytics = new GlobalAnalytics();
    const analytics = new Analytics(globalAnalytics);
    analytics.sendEvent("adRequest");
    analytics.sendEvent("adOpportunity");
    adDisplayContainer.initialize();
    adsLoader.requestAds(adsRequest);
  } catch (error) {

  }
}

/**
 * generate a radom string with given lenth
 *
 * @param {*} length
 * @returns {*}
 */
function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result.toUpperCase();
}

/**
 * config required ad break with given ad data
 *
 * @export
 */
export function configAdBreak() {
  let ad_data = appData.Ads;
  let duration = videoElement.duration;

  let adPod = parseInt(ad_data.adPod);
  let ad_breaks = [];
  var url = appData.Ads.vastURL;
  if (!appSettings.production) {
    url = appData.Ads.vastURL + "&custom_18=Test";
  }
  var sample_url = "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator="
  const macros = {
    "[VIDEO_TITLE]": channelInfo.title,
    "[VIDEO_CONTENT_ID]": channelInfo.id,
    "[PAL_STRING]": nonceManager?.nonce || ""
  };

  url = parser.parse(url, macros);
  // add preroll to adbreaks if allowed preroll
  if (ad_data.preRoll) {
    ad_breaks.push({
      time: 'start',
      url: url,
      played: false
    });
  }
  // add midRoll to adbreaks if allowed midRoll
  if (ad_data.midRoll) {
    let countAdBreaks = parseInt(duration / adPod);
    if (countAdBreaks > 1) {
      var adBreakOffSet = 0;
      for (let i = 0; i < countAdBreaks - 1; i++) {
        adBreakOffSet += adPod
        ad_breaks.push({
          time: adBreakOffSet,
          url: url,
          played: false
        })
      }
    }
  }
  // add postRoll to adbreaks if allowed postRoll
  if (ad_data.postRoll) {
    ad_breaks.push({
      time: 'end',
      url: url,
      played: false
    })
  }

  Player.ad_breaks = ad_breaks;
  window.ad_breaks = ad_breaks;
}

/**
 * when video finished playing, if there is post roll, then play post roll ad break
 */
function contentEndedListener() {
  try {
    adsLoader.contentComplete();
  } catch (e) {
  }
  let last_index = Player.ad_breaks.length - 1;
  let last_ad_break = Player.ad_breaks[last_index];
  if (Player.ad_breaks.length > 0 && last_ad_break.time == 'end' && !last_ad_break.played) {
    load_ima_ads(last_index);
  } else {
    HlsPlayer.autoPlayNext();
  }
};

/**
 *  initialize IMA in selected video.
 *
 * @export
 * @param {*} videoEl
 * @param {*} chInfo
 */
export function initializeIMA(videoEl, chInfo) {
  try {
    channelInfo = chInfo;
    videoElement = videoEl;
    adsLoaded = false;
    hasAds = true;
    videoEl.addEventListener('ended', contentEndedListener);

    adContainer = document.getElementById("ad_parent");
    if (google && google.ima) {
      adDisplayContainer = new google.ima.AdDisplayContainer(adContainer);
      adsLoader = new google.ima.AdsLoader(adDisplayContainer);
      adsLoader.addEventListener(
        google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
        onAdsManagerLoaded,
        false
      );
      adsLoader.addEventListener(
        google.ima.AdErrorEvent.Type.AD_ERROR,
        onAdError,
        false
      );
      // generateNonce()
    }
  } catch (error) {
  }
}

/**
 * Instantiate the AdsManager from the adsLoader response and pass it the video element
 *
 * @param {*} adsManagerLoadedEvent
 */
function onAdsManagerLoaded(adsManagerLoadedEvent) {
  var adsRenderingSettings = new google.ima.AdsRenderingSettings();
  adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;

  adsManager = adsManagerLoadedEvent.getAdsManager(videoElement);
  window.adsm = adsManager;

  adsManager.addEventListener(google.ima.AdEvent.Type.LOADED, onAdStarted);
  adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, onAdStarted);
  adsManager.addEventListener(google.ima.AdEvent.Type.COMPLETE, onAdStarted);
  adsManager.addEventListener(
    google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
    onContentPauseRequested
  );
  adsManager.addEventListener(
    google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
    onContentResumeRequested
  );

  adsManager.addEventListener(
    google.ima.AdEvent.Type.AD_BREAK_READY,
    onAdEvent
  );
  adsManager.addEventListener(
    google.ima.AdEvent.Type.AD_BREAK_STARTED,
    onAdEvent
  );
  adsManager.addEventListener(
    google.ima.AdEvent.Type.AD_BREAK_ENDED,
    onAdEvent
  );
  adsManager.addEventListener(
    google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
    onAdEvent
  );
  adsManager.addEventListener(google.ima.AdEvent.Type.IMPRESSION, onAdEvent);
  adsManager.addEventListener(
    google.ima.AdEvent.Type.FIRST_QUARTILE,
    onAdEvent
  );
  adsManager.addEventListener(
    google.ima.AdEvent.Type.MIDPOINT,
    onAdEvent
  );


  adsManager.addEventListener(
    google.ima.AdEvent.Type.THIRD_QUARTILE,
    onAdEvent
  );

  adsManager.addEventListener(
    google.ima.AdEvent.Type.LOG,
    onAdLog
  );

  adsManager.addEventListener(
    google.ima.AdErrorEvent.Type.AD_ERROR,
    onAdError
  );
  adsManager.addEventListener(
    google.ima.AdEvent.Type.AD_BREAK_FETCH_ERROR,
    (e) => { }
  );

  var width = videoElement.clientWidth;
  var height = videoElement.clientHeight;
  try {
    videoElement.removeEventListener('ended', contentEndedListener);
    adsManager.init(width, height, google.ima.ViewMode.NORMAL);
    adsManager.start();
  } catch (e) {
    if (videoElement.paused) videoElement.play();
  }

}
/**
 * handle ad non-fatal erros
 *
 * @param {*} adErrorEvent
 */
function onAdLog(adErrorEvent) {
}


/**
 * handle ad error event
 *
 * @param {*} adErrorEvent
 */
function onAdError(adErrorEvent) {

  adsLoaded = false;
  let current_ad_break = Player.ad_breaks[Player.current_ad_break_index];
  if (current_ad_break.time == 'end') {
    HlsPlayer.autoPlayNext();
  } else {
    try {
      if (appData) {
        retry_ad_break_index = Player.current_ad_break_index;
        let ad_data = appData.Ads;
        let retryTimeout = parseInt(ad_data.adInterval) * 1000;
        setTimeout(() => {
          if (retry_ad_break_index == Player.current_ad_break_index) {
            start_ads(Player.current_ad_break_index)
          }
        }, retryTimeout);
      }
    } catch (error) {

    }
  }
}

function onAdStarted(adEvent) {
  switch (adEvent.type) {
    case google.ima.AdEvent.Type.LOADED:
      // when ad got stuck, resume to next video automatically after ad duration.
      setTimeout(() => {
        if (Player.isAdPlaying) {
          adCompleteHandler();
          allAdsCompletedHandler();
        } else {
          displayLog("LOADED:TIMEOUT-> isAdPlaying: false")
        }
      }, adsManager.getRemainingTime() * 1000 + 500);
      window.time_load = new Date().getSeconds(); // the seconds of ad started      
      break;
    case google.ima.AdEvent.Type.STARTED:

      window.time_start = new Date().getSeconds(); // the seconds of ad started
      adStartedHandler(adEvent);
      break;
    case google.ima.AdEvent.Type.COMPLETE:
      adCompleteHandler();
      break;
    default:
  }
}

/**
 * this event will be used to pause the content when ad breaks are played
 *
 * @param {*} e
 */
function onContentPauseRequested(e) {
  onAdStarted(e)
  onAdEvent(e)

  isContentResumeRequested = false;
  window.is_ad_satarted = true;
  const globalAnalytics = new GlobalAnalytics();
  const analytics = new Analytics(globalAnalytics);
  analytics.sendEvent("adBreakStarted");
  videoElement.pause();

}

/**
 * this event will be used to resume the content when ad breaks are played
 *
 * @param {*} e
 */
function onContentResumeRequested(e) {
  onAdEvent(e)
  window.is_ad_satarted = false;
  isContentResumeRequested = true;
  const globalAnalytics = new GlobalAnalytics();
  const analytics = new Analytics(globalAnalytics);
  analytics.sendEvent("adBreakCompleted");
  if (!videoElement.paused) {
    return;
  }
  videoElement.play();

}


function destroy_add() {
  if (videoElement.paused && !videoElement.ended) videoElement.play();
  if (Player.current_ad_break_index == Player.ad_breaks.length - 1) {
    if (adsManager) {
      adsManager.destroy();
      adsManager = null;
    }
    const adParent = document.getElementById("ad_parent");
    if (adParent) {
      adParent.remove();
    }
    adsLoader = null;
    adDisplayContainer = null;
    adContainer = null;
    HlsPlayer.autoPlayNext();
  } else {
    videoElement.addEventListener('ended', contentEndedListener);
  }
  adsLoaded = false;
}

/**
 * all different ad events will be trigger here.
 *
 * @param {*} adEvent
 */
function onAdEvent(adEvent) {
  switch (adEvent.type) {
    case google.ima.AdEvent.Type.LOADED:
      break;
    case google.ima.AdEvent.Type.STARTED:
      // adStartedHandler(adEvent);
      break;
    case google.ima.AdEvent.Type.COMPLETE:
      adCompletedHandler();
      break;
    case google.ima.AdEvent.Type.IMPRESSION:
      onImpressionHandler();
      break;
    case google.ima.AdEvent.Type.AD_BREAK_READY:
      break;
    case google.ima.AdEvent.Type.AD_PROGRESS:
      break;
    case google.ima.AdEvent.Type.AD_BREAK_STARTED:
      onAdBreakStartedHandler();
      break;
    case google.ima.AdEvent.Type.AD_BREAK_ENDED:
      break;

    case google.ima.AdEvent.Type.FIRST_QUARTILE:
      onFirstQuartileHandler();
      break;
    case google.ima.AdEvent.Type.THIRD_QUARTILE:
      onThirdQuartileHandler();
      break;
    case google.ima.AdEvent.Type.ALL_ADS_COMPLETED:
      allAdsCompletedHandler();
      break;
  }
}

/**
 * This event is fired when an ad has completed its playback. It indicates that the ad has played all the way through to the end.
 */
function adCompletedHandler() {
  var adParent = document.getElementById("ad_parent");

  if (adParent) {
    adParent.remove();
  }

  const ad_duration_parent = document.getElementById("ad_duration_parent");

  if (ad_duration_parent) {
    ad_duration_parent.classList.add("hidden");
  }
}

/**
 * when all ads completed, will trigger here. and resume the video
 */
function allAdsCompletedHandler() {
  if (videoElement) {
    if (videoElement.paused) {
      displayLog("allAdsCompletedHandler: replay-success")
      videoElement.play();
    } else {
      displayLog("allAdsCompletedHandler: replay-failed")
    }
  } else {
    displayLog("allAdsCompletedHandler: videoElement is not defined ")
  }
}

/**
 * This event occurs when an ad break starts. It signifies that the first ad in the break is about to be played.
 *
 * @param {*} adEvent
 */
function adStartedHandler(adEvent) {
  const globalAnalytics = new GlobalAnalytics();
  const analytics = new Analytics(globalAnalytics);
  analytics.sendEvent("adStarted");
  clearInterval(adInterval);

  let adDuration = adEvent.ad.data.duration;

  const ad_duration_parent = document.getElementById("ad_duration_parent");
  const ad_duration_text = document.getElementById("ad_duration_text");
  ad_duration_parent.classList.remove("hidden");

  if (document.getElementById("app_loader")) {
    document.getElementById("app_loader").classList.remove("show");
  }

  Player.hidePlayerControls();
  Player.isAdPlaying = true;



  adInterval = setInterval(() => {
    var remainingTime = adsManager.getRemainingTime();
    ad_duration_text.innerHTML = "The video will start " + parseInt(remainingTime) + "s";
  }, 300);
}

/**
 * This event is triggered when an ad impression is recorded. An impression is typically counted when an ad is displayed to the user, ensuring it has been seen.
 */
function onImpressionHandler() {
  const googleAnalytics = new GlobalAnalytics();
  const analytics = new Analytics(googleAnalytics);
  analytics.sendEvent("impression");
}

/**
 * This event occurs when an ad break starts. It signifies that the first ad in the break is about to be played.
 */
function onAdBreakStartedHandler() {
  const googleAnalytics = new GlobalAnalytics();
  const analytics = new Analytics(googleAnalytics);
  analytics.sendEvent("adBreakStarted");
}

/**
 * This event is fired when the ad playback reaches the first quartile, which means 25% of the ad's duration has been played.
 */
function onFirstQuartileHandler() {
  const googleAnalytics = new GlobalAnalytics();
  const analytics = new Analytics(googleAnalytics);
  analytics.sendEvent("firstQuartile");
}

/**
 * This event occurs when the ad playback reaches the third quartile, indicating that 75% of the ad's duration has been played.
 */
function onThirdQuartileHandler() {
  const googleAnalytics = new GlobalAnalytics();
  const analytics = new Analytics(googleAnalytics);
  analytics.sendEvent("thirdQuartile");
}

/**
 * ad complete handler - restting player-the related variables with ad and hide ad duration layout, destory ad manager. and resume to next video.
 */
function adCompleteHandler() {

  clearInterval(adInterval);
  adInterval = null;
  const googleAnalytics = new GlobalAnalytics();
  const analytics = new Analytics(googleAnalytics);
  analytics.sendEvent("adComplete");
  Player.isAdPlaying = false;
  if (!isContentResumeRequested) {
    analytics.sendEvent("adBreakCompleted");
  }
  const ad_duration_text = document.getElementById("ad_duration_parent");
  if (ad_duration_text) {
    ad_duration_text.classList.add('hidden');
  }
  destroy_add();
}


/**
 * displayLog: use to debug the logs on physical devices, isTest should be true to see the logs on the player screen
 *
 * @param {*} text
 */
function displayLog(text) {
  if (appSettings.enable_log) {
    const app_log_parent = document.getElementById("app_log_parent");
    if (app_log_parent) {
      const count = document.getElementsByClassName("app-log-text").length
      const log_text = el("div", "app-log-text");
      log_text.innerHTML = (count + 1) + " " + text
      app_log_parent.prepend(log_text)
    }
  }
}

export { displayLog, contentEndedListener }
