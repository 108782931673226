import { underMaintenance } from "../components/underMaintenance";
import Beacons from "../services/beacons";
import { GET } from "./get";
import errorReport from "../services/errorReport";
import Parser from "./parser";
import ChannelSettings from "../settings/channelSettings";
import DeviceCenter from "../services/deviceCenter";


/**
 * translateJson
 *
 * @param {*} json
 * @param {*} vast
 * @returns {*}
 */
const translateJson = (json, vast) => {
  switch (json.Info.class) {
    case "7":
      return v7Init(json, vast);
    default:
      throw new Error(`Unsupported json version: ${json.Info.class}`);
  }
};

/**
 * load scripts by the platform
 *
 * @param {*} options
 * @returns {*}
 */
const loadScripts = (options) => {
  const listOffScriptsToLoad = [];

  if (options.pal) {
    listOffScriptsToLoad.push(() => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "//imasdk.googleapis.com/pal/sdkloader/pal.js";
        script.onload = () => resolve();
        script.onerror = () => reject();
        document.head.appendChild(script);
      });
    });
  }

  return Promise.all[listOffScriptsToLoad.map((promisefunc) => promisefunc())];
};

/**
 * function to initialize window settings 
 */
const setWindowData = () => {
  window.settings = {
    platform: "",
    platformSettings: {},
    appSettings: {},
    deepLinkData: {},
  };
};

/**
    *
    * @function generateLogicUrl
    * @description generates a logic url from the passed hash
    * @param {string} hash
    * @returns {string}
    *
 * Hash: TV unique id to determine a specific tv. will use to get the settings 
 * 4fpgggl6py => Fashion TV
 * njsfvtweaa => 24flix
 * s6xjws00o5 => Nollywood Capital TV
 * vklwkp7w2s => Unreal TV
 * ctxe85cvw5 => Auto Allstars
 * vwb1upv460 => Rep Dat TV
 * baqn4tlk8e => ScreenMagic TV
 * wdjzf9ayeb => DOC BOX
 * zvmhs9hfuj => black screen
 *  m1dk1729h5 => coffe jazz
 * j4lseacvi8 => bob the train
 * yzpgKjrDAM =>
 * xhxxamifyo => PopStar TV
 * 745mzqybbr => Made It Myself TV
 * j2r5jdz7xc => Brazil Times
 * 8mslt993x6 => High Octaine TV
 * 79gqu2zw9w => DangerTV
 *
 * @param {*} hash
 * @returns {string}
 */
const generateLogicUrl = (hash) => {
  return `https://logic.castify.ai/srv/logic/?hash=1rk064&cad[channel_id]=${hash}&rd=1&cad[client_type]=3&cad[channel_output]=_manifest`
}

/**
    * @function getLogicUrl
    * @description fetches the logic url of a channel hash, else defaults to high octane
    * @param {string} customHash
    * @returns {string}
    */
const getLogicUrl = (customHash) => {
    let hash;
    if (customHash) return generateLogicUrl(customHash);
    try {
        const params = new URLSearchParams(location.search);
        hash = params.get("hash");
        // hash = window.location.search.split("=")[1]; // Browser
        if (hash) return generateLogicUrl(hash);
    } catch (error) {

    }
    const elem = document.getElementById("jsonUrl"); // TV devices
    if (elem) {
        let url = elem.innerText.trim();
        if (!url) throw new Error("No url found");
        return url;
    } else {
        return generateLogicUrl("8mslt993x6") // High Octaine TV as a default
    }
};

/**
    * @async
    * @function getLogic
    * @description fetches and extracts data from the logic url - final click url and device information
    * @param {string} customHash
    * @returns {unknown}
    */
const getLogic = async (customHash) => {
    try {
        // Attempt to parse the logic URL and fetch data
        const logicUrl = Parser.parse(getLogicUrl(customHash));
        if (!logicUrl) {
            throw new Error("Failed to parse logic URL");
        }

        const response = await GET(logicUrl).catch((err) => {
            console.error("Error during GET request for logic URL:", err);
            throw new Error("Failed to fetch logic data");
        });

        const { finalClickUrl, requestData = {} } = response;

        // Validate the response structure
        if (!finalClickUrl) {
            throw new Error("Missing 'finalClickUrl' in response");
        }

        const { deviceData = {}, geoData = {} } = requestData;

        // Validate deviceData structure
        if (!deviceData.uaData) {
            throw new Error("Invalid 'deviceData': Missing 'uaData'");
        }

        // Construct userData
        const userData = {
            ip: deviceData.ip || "Unknown IP",
            default_IFA: deviceData.deviceIfa || "Unknown IFA",
            geoData: geoData || "No GeoData",
            ua: {
                osName: deviceData.uaData.osName || "Unknown OS Name",
                osFamily: deviceData.uaData.osFamily || "Unknown OS Family",
                uaFamily: deviceData.uaData.uaFamily || "Unknown UA Family",
                deviceType: deviceData.uaData.deviceType || "Unknown Device Type",
            },
        };

        // Return the final logic data
        return {
            url: finalClickUrl,
            userData,
        };
    } catch (error) {
        console.error("Error in getLogic:", error);

        // Track the error for monitoring purposes
        errorReport.trackError({
            message: error.message || "Unknown error",
            stack: error.stack || "No stack trace",
            additionalInfo: { customHash },
        });

        // Re-throw the error to propagate it
        throw error;
    }
};


/**
    * @async
    * @function destructures data from json composing the neccessary data into a menifest object
    * @param {string} url
    * @param {string} iso
    * @returns {Object}
    */
const getManifest = async (url, iso) => {
    try {
        // Fetch the manifest data
        const manifest = await GET(url).catch((err) => {
            console.error("Error during GET request for manifest:", err);
            throw new Error("Failed to fetch manifest data");
        });

        const newPlugins = await fetch("https://cdn.castify.ai/files/app/web/channel-settings/channel_settings.json");
        const theData = await newPlugins.json();

        window.newPlugins = theData;

        // Validate manifest structure
        if (!manifest) {
            throw new Error("Manifest data is undefined or null");
        }

        // manifest.channel_settings_url = "https://cdn.castify.ai/files/app/web/channel-settings/channel_settings.json";

        const channelStatus = manifest.channel_status;

        // Check the channel status
        if (channelStatus && channelStatus !== "Live") {
            throw new Error(`App status: ${channelStatus}`, { cause: channelStatus });
        }

        // Validate required fields in manifest
        if (!manifest.translation_url) {
            console.warn("Missing 'translation_url' in manifest");
        }

        if (!manifest.beacons) {
            console.warn("Missing 'beacons' in manifest");
        }

        if (!manifest.channel_settings_url) {
            console.warn("Missing 'channel_settings_url' in manifest");
        }

        // Return the processed manifest data
        return {
            translation_url: manifest.translation_url || null,
            splash_screen: manifest.splash_screen || null,
            feed: manifest.multi_geo_content?.[iso] || manifest.multi_geo_content?.all || null,
            beacons: manifest.beacons || null,
            channel_settings_url: manifest.channel_settings_url || null,
        };
    } catch (error) {
        console.error("Error in getManifest:", error);

        // Track the error for monitoring purposes
        errorReport.trackError({
            message: error.message || "Unknown error",
            stack: error.stack || "No stack trace",
            additionalInfo: { url, iso },
        });

        // Re-throw the error to propagate it
        throw error;
    }
};


/**
 * use to get app data
 *
 * @async
 * @param {*} apiUrl
 * @param {*} dataToReplace
 * @returns {unknown}
 */
const getAppJson = async (apiUrl, dataToReplace) => {
    try {
        // Fetch the JSON text from the API
        const jsonText = await GET(apiUrl, { type: "text" }).catch((err) => {
            console.error("Error during GET request for App JSON:", err);
            throw new Error("Failed to fetch App JSON data");
        });

        if (!jsonText) {
            throw new Error("Received empty or undefined JSON text from API");
        }

        // Replace placeholders and parse the JSON
        try {
            const parsedJson = JSON.parse(
                jsonText.replace(/\$\{USER_COUNTRY\}/g, dataToReplace)
            );

            if (!parsedJson) {
                throw new Error("Failed to parse JSON: Parsed data is null or undefined");
            }

            return parsedJson;
        } catch (parseError) {
            console.error("Error parsing JSON:", parseError);
            throw new Error("Failed to parse App JSON data");
        }
    } catch (error) {
        console.error("Error in getAppJson:", error);

        // Track the error for monitoring purposes
        errorReport.trackError({
            message: error.message || "Unknown error",
            stack: error.stack || "No stack trace",
            additionalInfo: { apiUrl, dataToReplace },
        });

        // Re-throw the error to propagate it
        throw error;
    }
};

/**
 * Get translations
 *
 * @async
 * @param {*} url
 * @returns {unknown}
 */
const getTranslation = async (url) => {
  if (!url) return langData;

  try {
    return await GET(url);
  } catch (error) {
    return langData;
  }
};


/**
 * Get channel settings
 *
 * @async
 * @param {*} channelSettingsHash
 * @returns {unknown}
 */

const getSettings = async (channelSettingsHash) => {
    try {
        // Check if channelSettingsHash is provided
        if (!channelSettingsHash) {
            console.warn("No channelSettingsHash provided. Initializing default settings.");
            return ChannelSettings.init();
        }

        // Fetch settings data
        console.log('channel settings', channelSettingsHash)
        const settings = await GET(channelSettingsHash).catch((err) => {
            console.error("Error during GET request for channel settings:", err);
            throw new Error("Failed to fetch channel settings");
        });

        window.brandSettings = settings;

        // Initialize ChannelSettings with fetched data
        return ChannelSettings.init(settings);
    } catch (error) {
        console.error("Error in getSettings:", error);

        // Log the error for monitoring
        errorReport.trackError({
            message: error.message || "Unknown error",
            stack: error.stack || "No stack trace",
            additionalInfo: { channelSettingsHash },
        });

        // Fallback to default settings in case of any errors
        console.warn("Falling back to default channel settings.");
        return ChannelSettings.init();
    }
};

/**
 * Get app data with the hash id
 *
 * @async
 * @param {string} customHash
 * @returns {object}
 */

const getAppData = async (customHash) => {
    try {
        // Set initial window data
        try {
            setWindowData();
        } catch (err) {
            console.error("Error in setWindowData:", err);
            throw err;
        }

        // Fetch logic data
        const logic = await getLogic(customHash).catch(err => {
            console.error("Error in getLogic:", err);
            throw new Error("Failed to fetch logic data");
        });

        if (!logic?.userData?.geoData?.cityGeoData?.countryIsoCode) {
            throw new Error("Invalid logic structure: Missing country ISO code");
        }

        const ISO = logic.userData.geoData.cityGeoData.countryIsoCode;

        // Fetch manifest data
        const manifest = await getManifest(logic.url, ISO).catch(err => {
            console.error("Error in getManifest:", err);
            throw new Error("Failed to fetch manifest data");
        });

        if (!manifest) {
            throw new Error("Manifest data is empty or undefined");
        }

        // Display splash screen if available
        if (manifest.splash_screen) {
            try {
                // Uncomment if displaySplashScreen is implemented
                // displaySplashScreen(manifest.splash_screen.url, manifest.splash_screen.duration);
            } catch (err) {
                console.error("Error in displaySplashScreen:", err);
                throw err;
            }
        }

        // Resolve all critical async operations
        // const data = await Promise.allSettled([
        //     DeviceCenter.init(logic.userData, ISO),
        //     getAppJson(manifest.feed.content_feed_url, ISO),
        //     getTranslation(manifest.translation_url),
        //     getSettings(manifest.channel_settings_url),
        // ]);
         const promises = [
            DeviceCenter.init(logic.userData, ISO),
            getAppJson(manifest.feed.content_feed_url, ISO),
            getTranslation(manifest.translation_url),
            getSettings(manifest.channel_settings_url),
        ];

        const data = await Promise.all(
            promises.map(promise => 
                promise
                    .then(value => ({ status: 'resolve', value }))
                    .catch(reason => ({ status: 'rejected', reason }))
            )
        );


        // Log rejected promises for debugging
        data.forEach((result, index) => {
            if (result.status === "rejected") {
                console.error(`Promise at index ${index} failed:`, result.reason?.stack || result.reason);
            }
        });

        // Ensure required data is present
        const [deviceInit, appJson, translation, settings] = data;

        if (appJson.status === "rejected" || !appJson.value) {
            throw new Error("Failed to fetch appJson");
        }
        if (translation.status === "rejected" || !translation.value) {
            throw new Error("Failed to fetch translation data");
        }

        // Load additional scripts based on appJson
        try {
            loadScripts({
                pal: appJson.value.Ads?.sdk_type === "pal",
            });
        } catch (err) {
            console.error("Error in loadScripts:", err);
            throw err;
        }

        // Initialize parser and beacons
        try {
            const [beaconUrl, beaconSession, beaconVideo, vastURL] = Parser.init({
                data: logic.userData,
                urls: [
                    manifest.beacons.url,
                    manifest.beacons.url_session,
                    manifest.beacons.url_video,
                    appJson.value.Ads?.vastURL,
                ],
            });

            console.log("VAST URL:", vastURL);

            Beacons.init({ beaconUrl, beaconSession, beaconVideo });
        } catch (err) {
            console.error("Error in Parser or Beacons initialization:", err);
            throw err;
        }

        // Return required data
        return {
            appJson: appJson.value,
            translation: translation.value,
        };
    } catch (error) {
        console.error("Error in getAppData:", error);

        // Report error and display maintenance screen
        errorReport.trackError({
            message: error?.message || "Unknown error",
            stack: error?.stack || "No stack trace",
            additionalInfo: { customHash },
        });

        const app_loader = document.getElementById("app_loader");
        if (app_loader) app_loader.classList.remove("show");

        underMaintenance();

        // Re-throw the error to propagate it
        throw error;
    }
};

// Global listener for unhandled rejections
window.addEventListener("unhandledrejection", (event) => {
    console.error("Unhandled rejection:", event.reason?.stack || event.reason);
    errorReport.trackError({
        message: event.reason?.message || "Unhandled rejection",
        stack: event.reason?.stack || "No stack trace",
    });
});


// Global listener for unhandled rejections
window.addEventListener("unhandledrejection", (event) => {
    console.error("Unhandled rejection:", event.reason?.stack || event.reason);
    errorReport.trackError({
        message: event.reason?.message || "Unhandled rejection",
        stack: event.reason?.stack || "No stack trace",
    });
});


export { getLogic, getAppData };
